import {
  ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState,
  FC,
  useMemo,
} from 'react';
import { DateString, EventKeyed } from '../../../contexts/EventsLazyContext';
import { ReactGliderContainer } from '../../ReactGlider';
import { useNumVisibleColumns } from 'src/hooks/calendar/useNumVisibleColumns';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { CalendarNavigation } from '../headers/CalendarNavigation';
import { GliderCalendar } from '../../calendar/calendar-daily/GliderCalendar';
import { memo } from '../../../util/memo';

export type CalendarDailyProps = {
  dayToEvents: Record<DateString, EventKeyed[]>;
  onCalendarEndReached: (direction: 'forward' | 'backward') => void;
  onDateSelect: (newDate: Date) => void;
  initialDate?: Date;
  Extension: ComponentType<{ date: Date }>;
  height?: string;
  placeholderText?: string;
  query?: string;
};

const deltaToDirection = (delta: number) => {
  return delta > 0 ? 'right' : 'left';
};

const SWIPE_THRESHOLD_PX = 5;

const CalendarDailyUnmemoized: FC<CalendarDailyProps> = ({
  dayToEvents,
  onCalendarEndReached,
  onDateSelect,
  height = '100%',
  initialDate,
  placeholderText,
  Extension,
  query,
}) => {
  const theme = useTheme();

  const memoizedDayToEvents = useMemo(() => {
    return dayToEvents;
  }, [dayToEvents]);

  const { numVisibleColumns } = useNumVisibleColumns();

  const [currentDate, setCurrentDate] = useState<Date>(
    initialDate || new Date(),
  );

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [leftLoadingState, setLeftLoadingState] = useState<
    'loading' | 'checkmark' | null
  >(null);
  useEffect(() => {
    if (leftLoadingState === 'loading') {
      onCalendarEndReached('backward');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftLoadingState]);

  const [rightLoadingState, setRightLoadingState] = useState<
    'loading' | 'checkmark' | null
  >(null);
  useEffect(() => {
    if (rightLoadingState === 'loading') {
      onCalendarEndReached('forward');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightLoadingState]);

  const userInteractedRef = useRef<'left' | 'right' | false>(false);

  const flagEndReached = useCallback(
    (cursor?: number) => {
      const newCursor = cursor || gliderRef.current?.cursor || 0;
      if (!userInteractedRef.current) {
        return;
      }
      if (newCursor <= 0 && userInteractedRef.current === 'left') {
        setLeftLoadingState('loading');
        userInteractedRef.current = false;
      } else if (
        newCursor >
          Object.keys(memoizedDayToEvents).length - 1 - numVisibleColumns &&
        userInteractedRef.current === 'right'
      ) {
        setRightLoadingState('loading');
        userInteractedRef.current = false;
      }
    },
    [memoizedDayToEvents, numVisibleColumns, userInteractedRef],
  );

  const draggingRef = useRef(false);
  const startXRef = useRef(0);

  useEffect(() => {
    if (!element) {
      return;
    }

    const trackMouseDown = ({ clientX }: MouseEvent) => {
      draggingRef.current = true;
      startXRef.current = clientX;
    };

    const trackMouseMove = ({ clientX }: MouseEvent) => {
      if (!draggingRef.current) {
        return;
      }

      const deltaX = clientX - startXRef.current;
      if (deltaX === 0) {
        return;
      }
      if (Math.abs(deltaX) >= SWIPE_THRESHOLD_PX) {
        userInteractedRef.current = deltaToDirection(deltaX);
        flagEndReached();
      }
    };

    const trackMouseUp = () => {
      draggingRef.current = false;
    };

    const trackTouchStart = (e: TouchEvent) => {
      draggingRef.current = true;
      startXRef.current = e.touches[0].clientX;
    };

    const trackTouchMove = (e: TouchEvent) => {
      if (!draggingRef.current) {
        return;
      }

      // Opposite direction from mouse
      const deltaX = startXRef.current - e.touches[0].clientX;

      if (Math.abs(deltaX) >= SWIPE_THRESHOLD_PX) {
        userInteractedRef.current = deltaToDirection(deltaX);
        flagEndReached();
      }
    };

    const trackTouchEnd = () => {
      draggingRef.current = false;
    };

    const trackWheel = ({ deltaX }: WheelEvent) => {
      if (deltaX === 0) {
        return;
      }
      userInteractedRef.current = deltaToDirection(deltaX);
    };

    element.addEventListener('mousedown', trackMouseDown);
    document.addEventListener('mousemove', trackMouseMove);
    document.addEventListener('mouseup', trackMouseUp);

    element.addEventListener('wheel', trackWheel);

    element.addEventListener('touchstart', trackTouchStart, {
      passive: true,
    });
    element.addEventListener('touchmove', trackTouchMove, {
      passive: true,
    });
    element.addEventListener('touchend', trackTouchEnd, {
      passive: true,
    });

    const removeListeners = () => {
      element.removeEventListener('mousedown', trackMouseDown);
      document.removeEventListener('mousemove', trackMouseMove);
      document.removeEventListener('mouseup', trackMouseUp);

      element.removeEventListener('wheel', trackWheel);

      element.removeEventListener('touchstart', trackTouchStart);
      element.removeEventListener('touchmove', trackTouchMove);
      element.removeEventListener('touchend', trackTouchEnd);
    };

    if (leftLoadingState === 'loading' || rightLoadingState === 'loading') {
      removeListeners();
    }

    return () => {
      if (!element) {
        return;
      }

      removeListeners();
    };
  }, [element, flagEndReached, leftLoadingState, rightLoadingState]);

  const gliderRef = useRef<ReactGliderContainer>(null);
  const shiftLeft = useCallback(() => {
    userInteractedRef.current = 'left';
    gliderRef.current?.shiftCursor(-1);
  }, []);

  const shiftRight = useCallback(() => {
    userInteractedRef.current = 'right';
    gliderRef.current?.shiftCursor(1);
  }, []);

  const shiftLeftMuch = useCallback(() => {
    userInteractedRef.current = 'left';
    gliderRef.current?.shiftCursor(-numVisibleColumns);
  }, [numVisibleColumns]);

  const shiftRightMuch = useCallback(() => {
    userInteractedRef.current = 'right';
    gliderRef.current?.shiftCursor(numVisibleColumns);
  }, [numVisibleColumns]);

  const selectDate = useCallback(
    (selectedDate: DateString) => {
      onDateSelect(new Date(selectedDate));
      setCurrentDate(new Date(selectedDate));
    },
    [onDateSelect],
  );

  useEffect(() => {
    if (leftLoadingState === 'loading') {
      const timer = setTimeout(() => {
        setLeftLoadingState('checkmark');
        setTimeout(() => {
          setLeftLoadingState(null);
        }, 2000);
      }, 1000);

      return () => {
        return clearTimeout(timer);
      };
    }

    return undefined;
  }, [leftLoadingState]);

  useEffect(() => {
    if (rightLoadingState === 'loading') {
      const timer = setTimeout(() => {
        setRightLoadingState('checkmark');
        setTimeout(() => {
          setRightLoadingState(null);
        }, 2000);
      }, 1000);

      return () => {
        return clearTimeout(timer);
      };
    }

    return undefined;
  }, [rightLoadingState]);

  return (
    <Stack
      borderRadius="10px"
      sx={{
        p: '8px',
        background: theme.palette.background.elevation[4],
      }}
    >
      <CalendarNavigation
        onLeft={shiftLeft}
        onRight={shiftRight}
        onDoubleLeft={shiftLeftMuch}
        onDoubleRight={shiftRightMuch}
        selectDate={selectDate}
        visibleDate={currentDate}
        leftState={leftLoadingState}
        rightState={rightLoadingState}
      />
      <GliderCalendar
        initialDate={currentDate}
        onCursorChange={flagEndReached}
        gliderRef={gliderRef}
        onElementMount={setElement}
        dayToEvents={memoizedDayToEvents}
        height={height}
        placeholderText={placeholderText}
        Extension={Extension}
        query={query}
      />
    </Stack>
  );
};

export const CalendarDaily = memo(CalendarDailyUnmemoized);
