import { useContext } from 'react';
import { memo } from '../../../util/memo';
import { Typography, Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { TournamentPayout } from './TournamentPayout';
import PeopleIcon from '@mui/icons-material/People';
import Tooltip from '@mui/material/Tooltip';

const TournamentCardInfoUnmemoized: React.FC = () => {
  const { title, gameTitle, competitorCount, maxTeamCount, maxTeamSize } =
    useContext(TournamentContext);
  const maxCompetitorCount = maxTeamSize * maxTeamCount;

  const { teamSizeIcon, tooltipTitle } =
    maxTeamSize === 1
      ? {
          teamSizeIcon: PersonIcon,
          tooltipTitle: 'Solo players only',
        }
      : {
          teamSizeIcon: PeopleIcon,
          tooltipTitle: `Teams of ${maxTeamSize}`,
        };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
        <TournamentPayout variant="body1" sx={{ fontWeight: 700 }} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 450,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          color="text.disabled"
        >
          {gameTitle}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <GradientTypography
            variant="subtitle1"
            gradientColor="primary.vertical"
          >
            {`${competitorCount}/${maxCompetitorCount}`}
          </GradientTypography>
          <Tooltip title={tooltipTitle}>
            <Stack>
              <GradientIcon
                gradientColor="primary.horizontal"
                IconComponent={teamSizeIcon}
                sx={{ width: 20, height: 20 }}
              />
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const TournamentCardInfo = memo(TournamentCardInfoUnmemoized);
