import { CalendarDay } from '../../calendar/calendar-daily/CalendarDay';
import { toLocalMidnightDate } from '../../../../functions/src/util/date/paramsUtc';
import { EventKeyed } from '../../../contexts/EventsLazyContext';
import { FC, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { OptionalWrapper } from '../../OptionalWrapper';
import { HighlightWrapper } from './HighlightWrapper';

const isCurrentDay = (dateFormatted: string): boolean => {
  const today = new Date();
  const todayFormatted =
    today.getFullYear() +
    '-' +
    String(today.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(today.getDate()).padStart(2, '0');

  return dateFormatted === todayFormatted;
};

export type CalendarDayTodaylightProps = {
  dateString: string;
  eventsOfDate: EventKeyed[];
  height: string;
  extension: ReactNode;
  onIntersect: () => void;
  intersected: boolean;
};

const CalendarDayTodaylightUnmemoized: FC<CalendarDayTodaylightProps> = ({
  dateString,
  eventsOfDate,
  height,
  extension,
  onIntersect,
  intersected,
}) => {
  const highlightToday = useMemo(() => {
    return isCurrentDay(dateString);
  }, [dateString]);

  return (
    <OptionalWrapper doWrap={highlightToday} Wrapper={<HighlightWrapper />}>
      <CalendarDay
        date={toLocalMidnightDate(dateString)}
        events={eventsOfDate}
        height={height}
        extension={extension}
        onIntersect={onIntersect}
        intersected={intersected}
      />
    </OptionalWrapper>
  );
};

export const CalendarDayTodaylight = memo(CalendarDayTodaylightUnmemoized);
