import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { memo } from '../util/memo';

export type HoverContextProps = {
  isHovered: boolean;
};

export const HoverContext = createContext<HoverContextProps | undefined>(
  undefined,
);

export type HoverProviderProps = {
  children: ReactNode;
};

export const useHover = () => {
  const context = useContext(HoverContext);
  if (!context) {
    throw new Error('useHover must be used within a HoverProvider');
  }
  return context;
};

const HoverProviderUnmemoized: FC<HoverProviderProps> = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const memoizedValue = useMemo(() => {
    return { isHovered };
  }, [isHovered]);

  return (
    <div
      onMouseEnter={() => {
        return setIsHovered(true);
      }}
      onMouseLeave={() => {
        return setIsHovered(false);
      }}
    >
      <HoverContext.Provider value={memoizedValue}>
        {children}
      </HoverContext.Provider>
    </div>
  );
};

export const HoverProvider = memo(HoverProviderUnmemoized);
