import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { GradientTypography } from '../../gradients/GradientTypography';
import Typography from '@mui/material/Typography';
// import { ShareUrlToClipboard } from './ShareUrlToClipboard';
import { useTheme } from '@mui/material/styles';

export type EventsCalendarHeaderProps = {
  Title?: ReactNode;
  description?: string;
  TitleRight?: ReactNode;
  Header?: ReactNode;
  Search?: ReactNode;
  children?: ReactNode;
};

const EventsCalendarHeaderUnmemoized: React.FC<EventsCalendarHeaderProps> = ({
  Title,
  TitleRight,
  description,
  Header,
  Search,
  children,
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        pt: 4,
        background: theme.palette.background.elevation[8],
        borderRadius: '10px',
      }}
    >
      <Stack direction="row" sx={{ justifyContent: 'space-between', px: 4 }}>
        <Stack>
          {Title && (
            <GradientTypography
              textTransform="uppercase"
              variant="h5"
              gradientColor={'primary.vertical'}
            >
              {Title}
            </GradientTypography>
          )}
          {description && (
            <Typography sx={{ ml: 0.5 }} color={'text.primary'}>
              {description}
            </Typography>
          )}
        </Stack>
        {TitleRight}
        {/* <ShareUrlToClipboard /> */}
      </Stack>
      {Header}
      {!!Search && (
        <Stack
          sx={{
            px: 4,
            pt: 2,
          }}
        >
          {Search}
        </Stack>
      )}
      {children}
    </Stack>
  );
};

export const EventsCalendarHeader = memo(EventsCalendarHeaderUnmemoized);
