import { useMemo } from 'react';
import { parseParamsUtcOffset } from '../../functions/src/util/date/paramsUtc';
import { useRouterState } from './routing/useRouterState';

export function useUtcOffset(): number {
  const [utcParam] = useRouterState({
    key: 'utc',
    location: 'segment',
    defaultValue: 'UTC+0',
  });

  const utcOffset = useMemo(() => {
    return parseParamsUtcOffset({ utc: utcParam });
  }, [utcParam]);

  return utcOffset;
}