import { memo } from '../../util/memo';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success'
  | 'royal';

export type GradientDividerProps = {
  color: Color;
};

export const GradientDivider = memo(function GradientDividerUnmemoized({
  color,
}: GradientDividerProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '2px',
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${
          theme.palette[String(color)].main
        }, rgba(0, 0, 0, 0))`,
        my: 2,
        width: '100%',
        marginBottom: '10px',
      }}
    />
  );
});
