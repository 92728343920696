import { useContext, useEffect, useState, useRef } from 'react';
import { memo } from '../../../util/memo';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { ImageOptimized } from '../../image/ImageOptimized';

export const IMG_URL_DEFAULT =
  '/assets/images/tournament-calendar/img-url-default.png';
export const SPONSOR_IMG_URL_DEFAULT = '/assets/images/logo.svg';

export const TWO_THOUSAND_MS = 2000;
export const MIN_VISIBILITY = 0.05;

const TournamentCardImageryUnmemoized = () => {
  const { tournamentThumbnailImgUrl = IMG_URL_DEFAULT, sponsors } =
    useContext(TournamentContext);
  const theme = useTheme();
  const sponsorCount = sponsors?.length || 0;
  const sponsorHeight = 94 / sponsorCount;
  const [currentSponsorIndex, setCurrentSponsorIndex] = useState(0);
  const componentRef = useRef();

  useEffect(() => {
    if (sponsorCount < 3) {
      return;
    }

    let interval;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio >= MIN_VISIBILITY) {
          interval = setInterval(() => {
            setCurrentSponsorIndex((prevIndex) => {
              return (prevIndex + 1) % sponsorCount;
            });
          }, TWO_THOUSAND_MS);
        } else {
          if (interval) {
            clearInterval(interval);
          }
        }
      },
      { threshold: MIN_VISIBILITY },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sponsorCount]);

  return (
    <Stack
      ref={componentRef}
      direction="row"
      sx={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: '8px',
        display: 'flex',
      }}
    >
      <ImageOptimized
        src={tournamentThumbnailImgUrl}
        height={103}
        width={!sponsorCount ? 274 : 240}
        style={{ borderRadius: '5px' }}
      />

      <Box
        width={48}
        sx={{
          background: theme.palette.background.elevation[14],
          borderRadius: '5px',
          flexDirection: 'column',
          alignItems: 'center',
          display: !sponsorCount ? 'none' : 'flex',
        }}
      >
        {sponsors?.map((sponsor, index) => {
          const sponsorImgUrl = sponsor.imgUrl || SPONSOR_IMG_URL_DEFAULT;
          const isVisible = sponsorCount < 3 || index === currentSponsorIndex;
          return (
            <Box
              key={index}
              sx={{
                height: sponsorCount >= 3 ? '100%' : sponsorHeight,
                width: '100%',
                '& img': {
                  p: '2px !important',
                },
                alignItems: 'center',
                justifyContent: 'center',
                display: isVisible ? 'flex' : 'none',
                transition: 'opacity 0.5s ease',
              }}
            >
              <ImageOptimized
                layout="fixed"
                height={sponsorCount >= 3 ? 94 : sponsorHeight}
                width={48}
                objectFit="contain"
                src={sponsorImgUrl}
                style={{ borderRadius: '5px' }}
              />
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};

export const TournamentCardImagery = memo(TournamentCardImageryUnmemoized);
