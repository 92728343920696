import { useCallback, MouseEvent, KeyboardEvent, useMemo } from 'react';
import { memo } from '../util/memo';
import {
  GradientBorder,
  GradientBorderProps,
} from 'src/components/gradients/GradientBorder';

export type SelectableProps<TValue> = Omit<
  GradientBorderProps,
  'disableBorder' | 'disableGlow' | 'onChange'
> & {
  value: TValue;
  isSelected?: boolean;
  onChange: (value: TValue, newState: boolean) => void;
};

export function SelectableUnmemoized<TValue>({
  children,
  value,
  isSelected = false,
  onChange,
  ...gradientProps
}: SelectableProps<TValue>) {
  const toggleSelection = useCallback(
    (e: MouseEvent | KeyboardEvent) => {
      e.stopPropagation();
      onChange(value, !isSelected);
    },
    [onChange, value, isSelected],
  );

  const toggleEnterOrSpace = useCallback(
    (e: KeyboardEvent) => {
      if (e?.key === 'Enter' || e?.key === 'Space') {
        toggleSelection(e);
      }
    },
    [toggleSelection],
  );
  const borderRadiusRoot = useMemo(() => {
    return isSelected ? '4px 10px 10px 4px' : '10px';
  }, [isSelected]);
  return (
    <GradientBorder
      {...gradientProps}
      borderWidth={2}
      disableBorder={!isSelected}
      disableGlow={true}
      onClick={toggleSelection}
      role="button"
      tabIndex={0}
      onKeyDown={toggleEnterOrSpace}
      sx={{
        '& .MuiBox-root': {
          borderRadius: borderRadiusRoot,
          cursor: 'pointer',
        },
      }}
    >
      {children}
    </GradientBorder>
  );
}

export const Selectable = memo(
  SelectableUnmemoized,
) as typeof SelectableUnmemoized;
