import { Timestamp } from 'firebase-admin/firestore';
import { toDateString } from '../dates/Dates';

export const HOUR_IN_MILLIS = 1000 * 60 * 60;

export const eventsTransform = <T extends { date: Timestamp }>(docData: T) => {
  return eventsTimezoneTransform(docData, 0);
};

export const eventsTimezoneTransform = <T extends { date: Timestamp }>(
  docData: T,
  utcOffset: number
) => {
  const dateOnlyString = toDateString(docData.date.toDate(), { utcOffsetHours: utcOffset });
  return {
    ...docData,
    dateDay: new Date(dateOnlyString).getTime(),
  };
};

export const dateOnlyMillis = (date: Date) => {
  return new Date(toDateString(date)).getTime();
};

export const registrationTimeTransform = <
  T extends { registrationTime?: Timestamp },
>(
  docData: T,
) => {
  const { registrationTime } = docData;
  if (registrationTime) {
    return {
      ...docData,
      dateDay: dateOnlyMillis(registrationTime.toDate()),
    };
  }
  return {
    ...docData,
  };
};
