import {
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
  ComponentType,
} from 'react';
import { Configure, Index, UseConfigureProps } from 'react-instantsearch';
import { CustomHitsBidirectional } from './CustomHitsBidirectional';
import { prefixIndex } from '../../../functions/src/util/algolia/prefixIndex';
import isEqual from 'fast-deep-equal/react';
import { Hit, OrNode } from '../../../functions/src/types/Hit';
import { memo } from '../../util/memo';
import { PreemptStateProvider } from '../../contexts/algolia/PreemptStateContext';
import { PreemptedInstantSearch } from './PreemptedInstantSearch';
import { AlgoliaLayout, CatalogWrapperProps } from './AlgoliaLayout';
import { EventsVerticalWrapper } from './catalog-wrappers/EventsVerticalWrapper';
import { dateOnlyMillis } from '../../../functions/src/util/algolia/eventsTransform';
import {
  EventHit,
  RenderCard,
  RenderWrapper,
} from './catalog-wrappers/EventsCalendar';
import { useChangedCount } from '../../hooks/useChangedCount';
import { useDebounceCallback } from '@react-hook/debounce';

export type Direction = 'forward' | 'backward';

export const CALENDAR_AD_FREQUENCY = 20 as const;

export const TOURNAMENT_CARD_BORDER_RADIUS = '10px';

export const PAGE_DEBOUNCE_MS = 300 as const;

export type DatedHit = Hit<{ dateDay: number }>;

export type CatalogWrapperBidirectionalProps<THit extends DatedHit> = {
  hits: THit[];
  header?: ReactNode;
  onLoadMore: (direction?: Direction) => void;
  Extension: ComponentType<{ date: Date }>;
  query?: string;
};

export type RenderCatalogWrapperBidirectional = FC<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CatalogWrapperBidirectionalProps<any>
>;

export type AlgoliaLayoutBidirectionalProps = {
  CatalogWrapperBidirectional: RenderCatalogWrapperBidirectional;
  transformHits?: (hits: EventHit<Date>[]) => OrNode<EventHit<Date>>[];
  transformHitsExtension?: (hits: EventHit<Date>[]) => OrNode<EventHit<Date>>[];
  header?: ReactNode;
  configureOptions: Omit<Record<string, UseConfigureProps>, 'query'>;
  Wrapper?: RenderWrapper<EventHit<Date>, Date>;
  Card?: RenderCard<EventHit<Date>, Date>;
};

const clean = (configureOptions: UseConfigureProps) => {
  const { index: __, ...cleaned } = configureOptions;
  return cleaned;
};

const AlgoliaLayoutBidirectionalUnmemoized = ({
  CatalogWrapperBidirectional,
  transformHits,
  transformHitsExtension,
  configureOptions,
  Wrapper,
  Card,
}: AlgoliaLayoutBidirectionalProps) => {
  const [query, setQuery] = useState<string | undefined>(undefined);

  const indexNames = Object.keys(configureOptions);
  const [forwardIndex, backwardIndex] = indexNames;

  const configureOptionsForward = useMemo(() => {
    return clean(configureOptions[String(forwardIndex)]);
  }, [configureOptions, forwardIndex]);

  const configureOptionsBackward = useMemo(() => {
    return clean(configureOptions[String(backwardIndex)]);
  }, [configureOptions, backwardIndex]);

  const forwardIndexPrefixed = prefixIndex(forwardIndex);
  const backwardIndexPrefixed = prefixIndex(backwardIndex);

  const [pageForward, setPageForward] = useState<(() => void) | null>(null);
  const debouncedPageForward = useDebounceCallback(
    () => {
      pageForward?.();
    },
    PAGE_DEBOUNCE_MS,
    true,
  );

  const [pageBackward, setPageBackward] = useState<(() => void) | null>(null);
  const debouncedPageBackward = useDebounceCallback(
    () => {
      pageBackward?.();
    },
    PAGE_DEBOUNCE_MS,
    true,
  );

  const pageMore = useCallback<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CatalogWrapperBidirectionalProps<any>['onLoadMore']
  >(
    (direction) => {
      if (direction === 'forward') {
        debouncedPageForward?.();
      } else if (direction === 'backward') {
        debouncedPageBackward?.();
      }
    },
    [debouncedPageForward, debouncedPageBackward],
  );

  const [backwardHits, setBackwardHits] = useState<EventHit<Date>[]>([]);
  const [forwardHits, setForwardHits] = useState<EventHit<Date>[]>([]);

  const combinedHits = useMemo(() => {
    return [...backwardHits, ...forwardHits];
  }, [forwardHits, backwardHits]);

  const combinedHitsTransformed = useMemo(() => {
    return transformHits ? transformHits(combinedHits) : combinedHits;
  }, [combinedHits, transformHits]);

  const CustomHitsForward = useMemo(() => {
    return (
      <CustomHitsBidirectional
        setShowMore={setPageForward}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setHits={setForwardHits as any}
      />
    );
  }, []);

  const CustomHitsBackward = useMemo(() => {
    return (
      <CustomHitsBidirectional
        setShowMore={setPageBackward}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setHits={setBackwardHits as any}
      />
    );
  }, []);

  const EventsVerticalWrapperTransformed = useCallback(
    (props: CatalogWrapperProps<EventHit<Date>>) => {
      return (
        <EventsVerticalWrapper
          transformHits={transformHitsExtension}
          infiniteLoad={false}
          Wrapper={Wrapper}
          Card={Card}
          {...props}
        />
      );
    },
    [Card, Wrapper, transformHitsExtension],
  );

  const Extension = useCallback(
    ({ date }) => {
      const dateDay = dateOnlyMillis(date);
      const today = dateOnlyMillis(new Date());
      const isCurrentOrFuture = dateDay >= today;

      const indexToUse = isCurrentOrFuture ? forwardIndex : backwardIndex;
      const updatedConfigureOptions = {
        ...configureOptions[String(indexToUse)],
        distinct: false,
        numericFilters: [`dateDay = ${dateDay}`],
        offset: 5,
        length: 1000,
      };

      return (
        <AlgoliaLayout
          CatalogWrapper={EventsVerticalWrapperTransformed}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          configureOptions={updatedConfigureOptions as any}
          index={forwardIndex}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configureOptions, EventsVerticalWrapperTransformed],
  );

  const setState = useCallback(
    ({
      uiState,
      setUiState,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      uiState: any;
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
      setUiState: (uiState: any | ((previousUiState: any) => any)) => void;
    }) => {
      for (const key in uiState['']) {
        const value = uiState[''][String(key)];
        uiState[String(forwardIndexPrefixed)][String(key)] = value;
        uiState[String(backwardIndexPrefixed)][String(key)] = value;
      }
      const newQuery = uiState['']?.query;
      setQuery(newQuery);

      delete uiState[''];
      setUiState(uiState);
    },
    // We have to add configureOptions here in order to trigger
    // PreemptedInstantSearch to change when configureOptions changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backwardIndexPrefixed, forwardIndexPrefixed, configureOptions],
  );

  // TODO: Eventually remove. This is a workaround because Algolia's
  // responsesCache and requestsCache treats all searches with the same
  // query and index as the same entries; even though the filters and
  // numericFilters of configureOptions can be completely different
  // between those two searches.
  // This solution disables caching as soon as it detects that
  // configureOptions has changed.
  const configureChangedCount = useChangedCount(configureOptions);

  return (
    <PreemptStateProvider>
      <PreemptedInstantSearch
        onStateChange={setState}
        caching={configureChangedCount === 0}
      >
        <Index indexName={forwardIndexPrefixed}>
          <Configure {...configureOptionsForward} query={query} />
          {CustomHitsForward}
        </Index>
        <Index indexName={backwardIndexPrefixed}>
          <Configure {...configureOptionsBackward} query={query} />
          {CustomHitsBackward}
        </Index>
        <CatalogWrapperBidirectional
          hits={combinedHitsTransformed}
          onLoadMore={pageMore}
          query={query}
          Extension={Extension}
        />
      </PreemptedInstantSearch>
    </PreemptStateProvider>
  );
};

export const AlgoliaLayoutBidirectional = memo(
  AlgoliaLayoutBidirectionalUnmemoized,
  (prevProps, nextProps) => {
    return isEqual(prevProps.configureOptions, nextProps.configureOptions);
  },
);

// const converter = ConverterFactory.buildDateConverter<EventHit<Date>>();

// const correctDateDay = (hit: Hit): DatedHit => {
//   if (!('phase' in hit)) {
//     return hit as DatedHit;
//   }
//   const hitConverted = converter.convertData(hit);

//   if (hitConverted.phase === 'live') {
//     hit.dateDay = Date.now();
//   } else {
//     hit.dateDay = hitConverted.date.getTime();
//   }
//   return hit as DatedHit;
// };

// const convertToDatedHit = (hit: Hit): DatedHit => {
//   return hit as DatedHit;
// };
