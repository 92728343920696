import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import { GradientBorder } from '../gradients/GradientBorder';

export type SideBarAccentProps = {
  gradientColor: string;
  children: ReactNode;
};

const SideBarAccentUnmemoized: React.FC<SideBarAccentProps> = ({
  gradientColor,
  children,
}) => {
  return (
    <GradientBorder
      gradientColor={gradientColor}
      borderRadius={'10px'}
      borderWidth={6}
      sx={{ borderRight: '0', borderTop: '0', borderBottom: '0' }}
      disableGlow
    >
      {children}
    </GradientBorder>
  );
};

export const SideBarAccent = memo(SideBarAccentUnmemoized);
